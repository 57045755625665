/*
 *@Date: 2023-01-05 15:07:30
 *@Description: submission 汇总
*/
<template>
	<div class='displayFlex flexColumn h100 boxSizing'>
		<Navbar :showBack="true" backgroundColor="#FF9941" color="#fff">{{Header}}</Navbar>
		<template v-if="submissionDetail.productLine === 'LI' || submissionDetail.productLine==='GI'">
			<!-- 顶部固定内容 ID、Create Date等-->
			<el-form inline class="displayFlex flexSpace selectForm" style="padding: 15px;">
				<el-form-item label="Policy ID">{{submissionDetail.policyNumber}}</el-form-item>
				<el-form-item label="Status">{{submissionDetail.policyStatus}}</el-form-item>
				<el-form-item label="Policy Status Date">{{submissionDetail.policyStatusDate}}</el-form-item>
			</el-form>
			<div style="border-bottom: 6px solid #e8e8e8;"></div>
			<!-- form表单 -->
			<div style="padding: 30px 80px;overflow-y: auto;">
				<MyTitle Title="Product" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Line">
								<span>{{submissionDetail.productLine}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Submission Type">
								<span>{{submissionDetail.submissionType}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Provider Code">
								<span>{{submissionDetail.providerCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Contract Currency">
								<span>{{submissionDetail.contractCurrency}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Plan Name">
								<span>{{submissionDetail.planName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Plan Code">
								<span>{{submissionDetail.planCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Name">
								<span>{{submissionDetail.productName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Product Code">
								<span>{{submissionDetail.productCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Premium Type">
								<span>{{submissionDetail.premiumType}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Payment Method">
								<span>{{submissionDetail.paymentMethod}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policyholder" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Commission Rep Code">
								<span>{{submissionDetail.commissionRepCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Commission Rep Name">
								<span>{{submissionDetail.commissionRepName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Code">
								<span>{{submissionDetail.servicingRepCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Name">
								<span>{{submissionDetail.servicingRepName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Insured" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Insured Same As Policy Owner">
								<span>{{submissionDetail.insuredSameAsPolicyOwner}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Insured Client Number">
								<span>{{submissionDetail.insuredClientNumber}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Introducer Code">
								<span>{{submissionDetail.introducerCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Introducer Name">
								<span>{{submissionDetail.introducerName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Insured ID Number">
								<span>{{submissionDetail.insuredIdNumber}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Insured Name">
								<span>{{submissionDetail.insuredName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policy Detail" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lodgment Type">
								<span>{{submissionDetail.lodgmentType}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Mailing Address Same As Residential Address">
								<span>{{submissionDetail.mailingAddrSameAsResidentialAddr}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Application Reference Number">
								<span>{{submissionDetail.applicationReferenceNumber}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Application Date">
								<span>{{submissionDetail.applicationDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Policy Owner Client Number">
								<span>{{submissionDetail.policyOwnerClientNumber}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Policy Owner ID Number">
								<span>{{submissionDetail.policyOwnerIdNumber}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Policy Owner Name">
								<span>{{submissionDetail.policyOwnerName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Policy Number">
								<span>{{submissionDetail.policyNumber}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Block & Street Name">
								<span>{{address.street}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Unit No.">
								<span>{{address.unitNo}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Building Name">
								<span>{{address.houseNumber}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Country">
								<span>{{submissionDetail.country}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Postal/Zip Code">
								<span>{{submissionDetail.zipCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Payment Frequency">
								<span>{{submissionDetail.paymentFrequency}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Modal Premium">
								<span>{{submissionDetail.modalPremium}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Policy Status">
								<span>{{submissionDetail.policyStatus}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Policy Status Date">
								<span>{{submissionDetail.policyStatusDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Issue Date">
								<span>{{submissionDetail.lssueDate}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Effective Date">
								<span>{{submissionDetail.effectiveDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Short Name for Provider">
								<span>{{submissionDetail.shortNameForProvider}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Provider Full Name">
								<span>{{submissionDetail.providerFullName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Main Rider">
								<span>{{submissionDetail.mainRider}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</template>
		<template v-if="submissionDetail.productLine === 'CI' || submissionDetail.productLine==='SE'">
			<!-- 顶部固定内容 ID、Create Date等-->
			<el-form inline class="displayFlex flexSpace selectForm" style="padding: 15px;">
				<el-form-item label="Policy ID">{{submissionDetail.accountNum}}</el-form-item>
				<el-form-item label="Status">{{submissionDetail.accountStatus}}</el-form-item>
				<el-form-item label="Policy Status Date">{{submissionDetail.accountStatusDate}}</el-form-item>
			</el-form>
			<div style="padding: 30px 80px;overflow-y: auto;">
				<MyTitle Title="Product" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Line">
								<span>{{submissionDetail.productLineName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Submission Type">
								<span>{{submissionDetail.submissionType}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Code">
								<span>{{submissionDetail.productCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Provider Code">
								<span>{{submissionDetail.providerCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Contract Currency">
								<span>{{submissionDetail.contractCurrency}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Plan Name">
								<span>{{submissionDetail.planName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policyholder" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Commission Rep Name">
								<span>{{submissionDetail.commissionRepName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Provider Full Name">
								<span>{{submissionDetail.providerFullName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Code">
								<span>{{submissionDetail.servicingRepCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Name">
								<span>{{submissionDetail.servicingRepName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Insured" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Introducer Code">
								<span>{{submissionDetail.introducerCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Introducer Name">
								<span>{{submissionDetail.introducerName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policy Detail" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lodgment Type">
								<span>{{submissionDetail.lodgmentType}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Mailing Address Same As Residential Address">
								<span>{{submissionDetail.mailingAddrSameAsResidentialAddr}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Application Reference Number">
								<span>{{submissionDetail.applicationReferenceNumber}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Application Date">
								<span>{{submissionDetail.applicationDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Mailing Address">
								<span>{{submissionDetail.mailingAddress}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Payment Method">
								<span>{{submissionDetail.paymentMethod}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Effective Date">
								<span>{{submissionDetail.effectiveDate}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Short Name for Provider">
								<span>{{submissionDetail.shortNameForProvider}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Payment Frequency">
								<span>{{submissionDetail.paymentFrequency}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Commission Rep Code">
								<span>{{submissionDetail.commissionRepCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Main Account Client Number">
								<span>{{submissionDetail.mainAccountClientNum}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Main Account ID Number">
								<span>{{submissionDetail.mainAccountIdNum}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Main Account Name">
								<span>{{submissionDetail.mainAccountName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="JoNumber - Int Account Same As Main Account">
								<span>{{submissionDetail.jointAccountSameAsMainAccount}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="JoNumber - Int Account Client Number">
								<span>{{submissionDetail.jointAccountClientNum}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="JoNumber - Int Account ID Number">
								<span>{{submissionDetail.jointAccountIdNum}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Account Type">
								<span>{{submissionDetail.accountType}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Account Name">
								<span>{{submissionDetail.accountName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Subscription Amount">
								<span>{{submissionDetail.subscriptionAmount}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Account Status">
								<span>{{submissionDetail.accountStatus}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Account Status Date">
								<span>{{submissionDetail.accountStatusDate}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Account Number">
								<span>{{submissionDetail.accountNum}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Sales Charge">
								<span>{{submissionDetail.salesCharge}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Product Type">
								<span>{{submissionDetail.productType}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</template>
		<template v-if="submissionDetail.productLine === 'AU'">
			<!-- 顶部固定内容 ID、Create Date等-->
			<el-form inline class="displayFlex flexSpace selectForm" style="padding: 15px;">
				<el-form-item label="Policy ID">{{submissionDetail.policyNumber}}</el-form-item>
				<el-form-item label="Status">{{submissionDetail.policyStatus}}</el-form-item>
				<el-form-item label="Policy Status Date">{{submissionDetail.policyStatusDate}}</el-form-item>
			</el-form>
			<div style="padding: 30px 80px;overflow-y: auto;">
				<MyTitle Title="Product" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Line">
								<span>{{submissionDetail.productLineName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Submission Type">
								<span>{{submissionDetail.submissionType}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Provider Code">
								<span>{{submissionDetail.providerCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Product Code">
								<span>{{submissionDetail.productCode}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Product Name">
								<span>{{submissionDetail.productName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policyholder" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Commission Rep Code">
								<span>{{submissionDetail.commissionRepCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Commission Rep Name">
								<span>{{submissionDetail.commissionRepName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Code">
								<span>{{submissionDetail.servicingRepCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Servicing Rep Name">
								<span>{{submissionDetail.servicingRepName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Insured" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Introducer Code">
								<span>{{submissionDetail.introducerCode}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Introducer Name">
								<span>{{submissionDetail.introducerName}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<MyTitle Title="Policy Detail" class="mb20px" />
				<el-form label-position="right" label-width="400px" :model="submissionDetail">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Lodgment Type">
								<span>{{submissionDetail.lodgmentType}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Mailing Address Same As Residential Address">
								<span>{{submissionDetail.mailingAddrSameAsResidentialAddr}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Application Reference Number">
								<span>{{submissionDetail.applicationReferenceNumber}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Application Date">
								<span>{{submissionDetail.applicationDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Mailing Address">
								<span>{{submissionDetail.mailingAddress}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Short Name for Provider">
								<span>{{submissionDetail.shortNameForProvider}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Provider Full Name">
								<span>{{submissionDetail.providerFullName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Product Type">
								<span>{{submissionDetail.productType}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Client Number">
								<span>{{submissionDetail.clientNum}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Client ID Number">
								<span>{{submissionDetail.clientIdNum}}</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Client Name">
								<span>{{submissionDetail.clientName}}</span>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Transaction Date">
								<span>{{submissionDetail.transactionDate}}</span>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</template>
	</div>
</template>

<script>
import Navbar from "components/Navbar.vue";
import MyTitle from '../../views/KYC/components/MyTitle.vue';
export default {
	components: { Navbar, MyTitle },
	data () {
		return {
			submissionDetail: {},
			address: {},
			Header: '',
		};
	},
	computed: {
	},
	watch: {},
	methods: {
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.submissionDetail = this.$store.state.Submission.submissionDetail
		this.Header = this.$router.currentRoute.query.header
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='scss' scoped>
.selectForm /deep/ .el-form-item__label,
.selectForm /deep/ .el-form-item__content {
	font-size: 18px;
}
.el-form /deep/ .el-form-item {
	margin-bottom: 0px !important;
}
</style>